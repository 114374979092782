import React from "react"
import PropTypes from "prop-types"
import './footer.scss';
// import logoWhite from "../../images/webinson_logo_without_subtitle_white.svg"
import logoBlack from "../../images/webinson_logo_without_subtitle_black_new_font.svg"


const Footer = props => {
  return (
    <footer>
      <div className="container">
        <div className="col">
          <img src={logoBlack} alt={"logo"}/>
          © 2015 - {new Date().getFullYear()}
        </div>
        <div className="col">
          <h3>Company Info</h3>
          <p>
            <strong>Name</strong> Webinson, s.r.o.<br/>
            <strong>IČO</strong> 50399411<br/>
            <strong>DIČ</strong> 2120324261<br/>
            <strong>IČ DPHSK</strong> 2120324261
          </p>
        </div>
        <div className="col">
          <h3>Contact</h3>
          <p>
            <strong>Email</strong> <a href={'mailto:info@webinson.com'}>info@webinson.com</a><br/>
            <strong>Phone</strong> <a href={'tel:+421944070222'}>+421944070222</a><br/>
          </p>
        </div>

      </div>
    </footer>
  )
}

Footer.propTypes = {

}

export default Footer

import PropTypes from "prop-types"
import React, { useState } from "react"
import "./header.scss"
import logoWhite from "../../images/webinson_logo_without_subtitle_white.svg"
import logoBlack from "../../images/webinson_logo_without_subtitle_black_new_font.svg"

export const scrollIntoView = (elementId, event) => {
  event.preventDefault();
  document.getElementById(elementId).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
}

const Header = ({ siteTitle, isDark, setIsContactOpen }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className={`Header ${isOpen ? 'open' : ''}`}>
      <img src={isDark ? logoBlack : logoWhite} alt={"logo"}/>
      <nav onClick={() => setIsOpen(false)}>
        <a onClick={(e) => scrollIntoView("home", e)} href={"#home"}>Home</a>
        <a onClick={(e) => scrollIntoView("experience", e)} href={"#experience"}>Experience</a>
        <a onClick={(e) => scrollIntoView("our-work", e)} href={"#our-work"}>Our Work</a>
        <a onClick={(e) => setIsContactOpen(true)} href={"#contact-us"} className={"featured"}>Contact Us</a>
      </nav>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 459 459" className={'mobile-menu'} onClick={() => setIsOpen(true)}>
          <g>
            <g id="menu">
              <path d="M0,382.5h459v-51H0V382.5z M0,255h459v-51H0V255z M0,76.5v51h459v-51H0z"/>
            </g>
          </g>
        </svg>
    </header>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
  isDark: PropTypes.bool,
  setIsContactOpen: PropTypes.func.isRequired,
}

Header.defaultProps = {
  siteTitle: ``,
  isDark: true
}

export default Header
